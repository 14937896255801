import { GetEndpoint } from '../../src/lib/ajax/getEndpoint';
import { PostEndpoint } from '../../src/lib/ajax/postEndpoint';
import { getAdministrationEntries, getCurrentSecurityCard } from '../emksAdministrationController';
import { getKeySystemDownloadStatus } from '../emksFileController';
import { getKeySystemHistoryEntries, getPartners, getSecuritySettings } from '../emksKeySystemController';
import { AuthenticationMode, AuthenticationRequest, AuthenticationResponse } from '../types/emksAuthentication';
import { ApiError } from '../types/error';
import { EmksCustomerSegment } from '../../../user-service';

let limitedLoginFlag: boolean = false;

/**
 * Connect a keySystemCode with a given token
 */
export const performAuthentication = PostEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		systemCode: string;
		customerSegment: EmksCustomerSegment;
		headPositionCode?: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		isPartnerDownloadArea?: boolean;
	},
	AuthenticationRequest,
	AuthenticationResponse | ApiError
>({
	url: ({ systemCode, customerSegment, headPositionCode, language, hybrisStore, user, rootB2BUnit, isPartnerDownloadArea }) => {
		const headPositionCodeParam = headPositionCode ? `&headPositionCode=${headPositionCode}` : '';
		const zkPartnerParam = headPositionCode ? '' : '&zkPartner=';
		const isPartnerDownloadAreaParam = isPartnerDownloadArea ? `&isPartnerDownloadArea=${isPartnerDownloadArea}` : '';
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${systemCode}/authenticate?lang=${language}&customerSegment=${customerSegment}&rootB2BUnit=${rootB2BUnit}${headPositionCodeParam}${isPartnerDownloadAreaParam}${zkPartnerParam}`;
	},
	options: ({ token }) => ({
		afterSuccess: () => {
			// Invalidate all get requests which rely on key system keyCode authentication
			getKeySystemHistoryEntries.clearCache();
			getPartners.clearCache();
			getSecuritySettings.clearCache();
			getKeySystemDownloadStatus.clearCache();
			// Clear mksAdministrationController
			getAdministrationEntries.clearCache();
			getCurrentSecurityCard.clearCache();
		},
		headerOptions: { authToken: token },
	}),
});

/**
 * Checks if the current authentication token is still valid
 */
export const validateAuthentication = GetEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		systemCode: string;
		customerSegment: EmksCustomerSegment;
		token: string;
		hybrisStore: string;
		user: string;
		headPosition?: string;
		rootB2BUnit: string;
		limitedLogin?: boolean;
	},
	AuthenticationResponse | ApiError
>({
	url: ({ hybrisStore, systemCode, customerSegment, language, user, headPosition, rootB2BUnit, limitedLogin }) => {
		const headPositionParam = headPosition ? `&headPositionCode=${headPosition}` : '';
		limitedLoginFlag = limitedLogin ? limitedLogin : false;
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${systemCode}/authentication/validate?lang=${language}&zkPartner=${headPositionParam}&customerSegment=${customerSegment}&rootB2BUnit=${rootB2BUnit}&limitedLogin=${limitedLoginFlag}`;
	},
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

/**
 * Checks if the current authentication token is still valid
 */
export const confirmAuthentication = PostEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		systemCode: string;
		token: string;
		hybrisStore: string;
		user: string;
		headPosition?: string;
		rootB2BUnit: string;
		limitedLogin?: boolean;
	},
	void,
	void
>({
	url: ({ hybrisStore, systemCode, language, user, headPosition, rootB2BUnit, limitedLogin }) => {
		const headPositionParam = headPosition ? `&headPositionCode=${headPosition}` : '';
		const zkPartnerParam = headPosition ? '' : '&zkPartner=';
		const limitedLoginParm = limitedLogin ? `&limitedLogin=${limitedLogin}` : '';
		limitedLoginFlag = limitedLogin;
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${systemCode}/authentication/confirm?lang=${language}&rootB2BUnit=${rootB2BUnit}${headPositionParam}${zkPartnerParam}${limitedLoginParm}`;
	},
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

/**
 *  In validate the current authentication token.
 */
export const inValidateAuthentication = PostEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		systemCode: string;
		headPositionCode?: string;
		token: string;
		hybrisStore: string;
		user: string;
		headPosition?: string;
		rootB2BUnit: string;
	},
	void,
	void
>({
	url: ({ hybrisStore, systemCode, language, user, headPosition, rootB2BUnit }) => {
		const headPositionParam = headPosition ? `&headPositionCode=${headPosition}` : '';
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${systemCode}/authentication/invalidate?language=${language}&zkPartner=${headPositionParam}&rootB2BUnit=${rootB2BUnit}`;
	},
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

/**
 * This endpoint is only for testing cases
 */
export const getAuthenticationDummy = GetEndpoint.create<
	{ statusCode: number; authenticationMode?: AuthenticationMode },
	XMLHttpRequest
>({
	url: ({ statusCode, authenticationMode }) =>
		`/dummyEndpoint/?statusCode=${statusCode}${
			authenticationMode ? '&authenticationMode=' + authenticationMode : ''
		}`,
	// The request is only used by the user service
	// which has it's own cache
	cacheEnabled: false,
});

/**
 * This endpoint is only for testing cases.
 */
export const getErrorWithMessage = GetEndpoint.create<{}, XMLHttpRequest>({
	url: () => `/errorWithMessageEndpoint`,
	cacheEnabled: false,
});

/**
 * This endpoint is only for testing cases.
 */
export const getErrorWithMKSMessage = GetEndpoint.create<{}, XMLHttpRequest>({
	url: () => `/errorWithMKSMessageEndpoint`,
	cacheEnabled: false,
});

export const postAuthenticationDummy = PostEndpoint.create<
	{
		statusCode: number;
		authenticationMode?: AuthenticationMode;
	},
	{},
	// Posting a save will return the external config idas string, with which you can
	// edit at a later point.
	AuthenticationResponse | any
>({
	url: ({ statusCode, authenticationMode }) =>
		`/dummyEndpoint/?statusCode=${statusCode}${
			authenticationMode ? '&authenticationMode=' + authenticationMode : ''
		}`,
});
